import { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import axios from "axios"

function App() {
  useEffect(() => {
    questChecker()
  }, []);

  const questChecker = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const questId = urlParams.get('questid');
    const userId = urlParams.get('userid');

    if (questId && userId) {
      await axios.post(`https://api-prod.weirdoghost.com/tg/quest/complete`, {
        tgId: Number(userId),
        questId: Number(questId)
      })
      window.location.href = 'https://x.com/outaxyz/status/1832691995246055882';
    }
  }

  return (
    <div className="App">
    </div>
  );
}

export default App;
